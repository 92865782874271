import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { animated, useSpring, config } from 'react-spring'
import Layout from '../components/layout'
import GridItem from '../components/grid-item'
import SEO from '../components/SEO'
import { ChildImageSharp } from '../types'

import { AnimatedBox } from '../elements'

type PageProps = {
  data: {
    twoProjects: {
      nodes: {
        title: string
        slug: string
        cover: ChildImageSharp
      }[]
    }
    aftercare: ChildImageSharp
    faq: ChildImageSharp
    contact: ChildImageSharp
    booking: ChildImageSharp
  }
}

const Area = styled(animated.div)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 35vw 25vw 25vw 35vw;
  grid-template-areas:
    'two-projects two-projects two-projects'
    'contact faq aftercare'
    'announcements announcements announcements'
    'booking booking booking';

  @media (max-width: ${props => props.theme.breakpoints[3]}) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 40vw 30vw 35vw 40vw;

    grid-template-areas:
      'two-projects two-projects two-projects'
      'contact faq aftercare'
      'announcements announcements announcements'
      'booking booking booking';
  }

  @media (max-width: ${props => props.theme.breakpoints[2]}) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 50vw 35vw 45vw 50vw;

    grid-template-areas:
      'two-projects two-projects two-projects'
      'contact faq aftercare'
      'announcements announcements announcements'
      'booking booking booking';
  }

  @media (max-width: ${props => props.theme.breakpoints[1]}) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 80vw 40vw 40vw 60vw 40vw;

    grid-template-areas:
      'two-projects two-projects'
      'contact faq'
      'aftercare aftercare'
      'announcements announcements'
      'booking booking';
  }

  @media (max-width: ${props => props.theme.breakpoints[0]}) {
    grid-template-columns: 1fr;
    grid-template-rows: 150vw 75vw 75vw 75vw 130vw 75vw;

    grid-template-areas:
      'two-projects'
      'contact'
      'faq'
      'aftercare'
      'announcements'
      'booking';
  }
`

const Aftercare = styled(GridItem)`
  grid-area: aftercare;
`

const FAQ = styled(GridItem)`
  grid-area: faq;
`

const Contact = styled(GridItem)`
  grid-area: contact;
`

const AnnouncementBox = styled(GridItem)`
  grid-area: announcements;
  position: relative;
  background-image: linear-gradient(to right, white, #ffc3a0, #FFAFBD);
  color: black;
`

const CenteredContent = styled.div`
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
`

const Booking = styled(GridItem)`
  grid-area: booking;
`

const TwoProjects = styled.div`
  grid-area: two-projects;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: ${props => props.theme.breakpoints[1]}) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
`

const Index: React.FunctionComponent<PageProps> = ({ data: { twoProjects, aftercare, faq, contact, announcements, booking, allMarkdownRemark } }) => {
  const pageAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 },
  })

  return (
    <Layout>
      <SEO />
      <Area style={pageAnimation}>
        <TwoProjects>
          {twoProjects.nodes.map(project => (
            <GridItem to={project.slug} key={project.slug} aria-label={`View project "${project.title}"`}>
              <Img fluid={project.cover.childImageSharp.fluid} />
              <span>{project.title}</span>
            </GridItem>
          ))}
        </TwoProjects>
        <Contact to="/contact" aria-label="Contact Page">
          <Img fluid={contact.childImageSharp.fluid} />
          <span>Contact</span>
        </Contact>
        <Aftercare to="/aftercare" aria-label="Tattoo Aftercare Instructions">
          <Img fluid={aftercare.childImageSharp.fluid} />
          <span>Aftercare</span>
        </Aftercare>
        <FAQ to="/faq" aria-label="FAQ">
          <Img fluid={faq.childImageSharp.fluid} />
          <span>FAQ</span>
        </FAQ>
        <AnnouncementBox to="/announcements" aria-label="Announcements">
          <AnimatedBox py={[6, 6, 6, 8]} px={[6]}>
            <CenteredContent>
              <h1>
                Latest Announcements
              </h1>
              {allMarkdownRemark.edges.map(({ node }) => (
                <div style={{paddingBottom: '2em'}} key={node.id}>
                  <h3>
                    {node.frontmatter.title}{" "}
                    <span style={{fontSize: '16px'}}>
                      | {node.frontmatter.date}
                    </span>
                  </h3>
                  <div
                    className="blog-post-content"
                    dangerouslySetInnerHTML={{ __html: node.html }}
                  />
                </div>
              ))}
            </CenteredContent>
          </AnimatedBox>
        </AnnouncementBox>
        <Booking to="/booking" aria-label="Booking">
          <Img fluid={booking.childImageSharp.fluid} />
          <span>Booking</span>
        </Booking>
        </Area>
    </Layout>
  )
}

export default Index

export const query = graphql`
  query Index {
    twoProjects: allProjectsYaml {
      nodes {
        title
        slug
        cover {
          childImageSharp {
            fluid(quality: 95, maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    aftercare: file(sourceInstanceName: { eq: "images" }, name: { eq: "aftercare" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    faq: file(sourceInstanceName: { eq: "images" }, name: { eq: "faq" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    contact: file(sourceInstanceName: { eq: "images" }, name: { eq: "contact" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    booking: file(sourceInstanceName: { eq: "images" }, name: { eq: "booking" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, limit: 1) {
      totalCount
      edges {
        node {
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
          }
          html
        }
      }
    }
  }
`
